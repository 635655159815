import React from "react";
import "./Ruko.scss";
import {
  nsdSlider,
  nsdUnit,
  dvSlider,
  dvUnit,
  dvPoint1,
  dvPoint2,
} from "../../media/data/RukoData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faAlignJustify,
  faHouse,
  faMaximize,
  faShower,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
const Ruko = () => {
  const settingsNsdSlider = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settingsNsdUnitSlider = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
  };
  const settingsDvSlider = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile
          swipeToSlide: true,
        },
      },
    ],
  };

  const wapromo = (rukoObj) => {
    const baseLink = "https://api.whatsapp.com/send/?phone=6281287877618";
    const text =
      "Halo+Addy%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20";
    const domainLink = "https://marketing-kotawisata.com/";
    const fullLink = `${baseLink}&text=${text}(${rukoObj.unit})+${domainLink}`;
    window.open(fullLink, "_blank");
  };
  return (
    <section id="ruko" className="section-ruko">
      <h1 className="section-title">Commercial Area</h1>
      <div className="ruko-container-1">
        <h2 className="section-subtitle">
          RUKO NORTH SQUARE DISTRICT : TANPA DP, START FROM 2M
        </h2>
        <div className="slider-1">
          <Slider {...settingsNsdSlider}>
            {nsdSlider.map((slide, index) => (
              <div className="slider-container-1" key={index}>
                <img src={slide} alt="ruko-nsd" className="image-slider-1" />
              </div>
            ))}
          </Slider>
        </div>
        <div className="container-card-1">
          {nsdUnit.map((item, index) => (
            <div className="card" key={index}>
              <Slider {...settingsNsdUnitSlider}>
                {item.images.map((imagePath) => (
                  <img
                    src={imagePath}
                    alt="ruko-nsd"
                    className="kartu-gambar"
                  />
                ))}
              </Slider>

              <div className="card-description">
                <div className="gridspek">
                  <FontAwesomeIcon icon={faAlignJustify} />
                  <span>: {item.lantai}</span>
                  <FontAwesomeIcon icon={faShower} />
                  <span>: {item.kamarMandi}</span>
                  <FontAwesomeIcon icon={faMaximize} />
                  <span> : {item.luasTanah}m²</span>
                  <FontAwesomeIcon icon={faHouse} />
                  <span> : {item.luasBangunan}m²</span>
                </div>
                <div>
                  <h2>{item.name}</h2>
                </div>
                <div className="highlight">{item.dp}</div>
                <div className="containerwhatsapp">
                  <button onClick={() => wapromo(item)} className="whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ruko-container-2">
        <h2 className="section-subtitle">RUKO DEVANT : START FROM 7M</h2>
        <div className="slider-2">
          <Slider {...settingsDvSlider}>
            {dvSlider.map((slide, index) => (
              <div className="slider-container-1" key={index}>
                <img src={slide} alt="ruko-devant" className="image-slider-1" />
              </div>
            ))}
          </Slider>
        </div>
        <div className="ruko-description">
          <div className="point-container">
            {dvPoint1.map((item) => (
              <div className="ruko-point">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  style={{ width: "20px", height: "20px" }}
                />
                <span style={{ color: "black" }}>&nbsp;&nbsp;{item}</span>
              </div>
            ))}
          </div>
          <div className="point-container">
            {dvPoint2.map((item) => (
              <div className="ruko-point">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  style={{ width: "20px", height: "20px" }}
                />
                <span style={{ color: "black" }}>&nbsp;&nbsp;{item}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="container-card-2">
          {dvUnit.map((item, index) => (
            <div className="card" key={index}>
              <div className="card-description">
                <div className="gridspek">
                  <FontAwesomeIcon icon={faAlignJustify} />
                  <span>: {item.lantai}</span>
                  <FontAwesomeIcon icon={faShower} />
                  <span>: {item.kamarMandi}</span>
                  <FontAwesomeIcon icon={faMaximize} />
                  <span> : {item.luasTanah}m²</span>
                  <FontAwesomeIcon icon={faHouse} />
                  <span> : {item.luasBangunan}m²</span>
                </div>
                <div>
                  <h2>{item.name}</h2>
                </div>
                <div className="highlight">{item.dp}</div>
                <div className="containerwhatsapp">
                  <button onClick={() => wapromo(item)} className="whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Ruko;
